import React from 'react';
import { rgba } from 'polished';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// Config
import { styleConfig } from '../../config/config';

const SliderNavigtion: React.FunctionComponent<SliderNavigtionProps> = (props) => {
  const { handleNext, handlePrev, slideIndex, slidesTotal, style, showCount } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: 'flex',
      position: 'relative',
      height: 52,
      top: 0,
      zIndex: 600,

      '& > p': {
        alignItems: 'center',
        color: rgba(
          style === 'dark' ? theme.palette.common.white : theme.palette.primary.dark,
          0.6
        ),
        display: 'flex',
        fontSize: 14,
        lineHeight: 1,
        letterSpacing: 2,
        margin: 0,
        padding: '19px 25px',
      },
    },
    icon: {
      width: 32,
      color: style === 'dark' ? '#FFF' : styleConfig.color.text.lightgrey,
      transition: '333ms ease-in-out all',

      '&:hover': {
        color: styleConfig.color.text.gold,
      },
    },
    count: {
      width: 'auto',
      backgroundColor: style === 'dark' ? '#212121' : styleConfig.color.bg.offwhite,
    },
    button: {
      backgroundColor: style === 'dark' ? theme.palette.primary.dark : '#F0F0F0',
      border: 0,

      height: 52,
      cursor: 'pointer',
      lineHeight: 0,
      outline: 0,
      '&:hover': {
        '& svg polyline': {
          stroke: style === 'dark' ? theme.palette.common.white : theme.palette.primary.main,
        },
      },
    },
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.root}>
        {showCount && (
          <p className={classes.count}>
            {slideIndex + 1} of {slidesTotal}
          </p>
        )}

        <button
          aria-label="Previous item"
          className={classes.button}
          disabled={slideIndex === 0}
          onClick={() => handlePrev()}
        >
          <ChevronLeftIcon className={classes.icon} />
        </button>
        <button
          aria-label="Next item"
          className={classes.button}
          disabled={slideIndex + 1 === slidesTotal}
          onClick={() => handleNext()}
        >
          <ChevronRightIcon className={classes.icon} />
        </button>
      </div>
    </React.Fragment>
  );
};

interface SliderNavigtionProps {
  showCount?: boolean;
  handlePrev: Function;
  handleNext: Function;
  slideIndex: number;
  slidesTotal: number;
  style?: 'dark' | 'light';
}

export default SliderNavigtion;
